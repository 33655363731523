var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings__pages"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Settings")]),_c('div',[_vm._v(" Expanding new sales channels helps you sell to new customers online, on mobile apps, through social networks, and in person. ")])]),_vm._l((_vm.pages),function(page,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4","sm":"12","xs":"12"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
          name:
            page.router !== 'website.settings.payments' || _vm.utilities.checkPermisson("payment_providers", _vm.user)
              ? page.router
              : 'no-access',
        }}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto card__background",class:{ 'on-hover': hover },attrs:{"height":"125","elevation":hover ? 8 : 1}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"3","xs":"3","sm":"3","md":"3"}},[_c('v-img',{attrs:{"max-width":"48px","src":page.icon,"alt":page.des}})],1),_c('v-col',{attrs:{"cols":"9","xs":"9","sm":"9","md":"9"}},[_c('h3',{staticClass:"text-decoration-none"},[_vm._v(_vm._s(page.title))]),_c('p',{staticClass:"text-decoration-none mt-3"},[_vm._v(_vm._s(page.des))])])],1)],1)],1)]}}],null,true)})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }